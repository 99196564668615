<template>
  <Popper
    offsetDistance="4"
    :placement="placement"
    :hover="hover"
    :show="isShow"
    :class="{ dense, transparent }"
  >
    <slot name="activator" />
    <template #content>
      <slot />
    </template>
  </Popper>
</template>
<script>
import { defineComponent } from 'vue';
import Popper from 'vue3-popper';

export default defineComponent({
  name: 'Popover',
  components: { Popper },
  props: {
    placement: String, // 'top' | 'left' | 'right' | 'bottom'
    hover: {
      type: Boolean,
      default: false
    },
    isShow: {
      type: Boolean || undefined,
      default: undefined
    },
    transparent: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    }
  }
});
</script>
<style lang="scss">
@import '@/styles/spacing';

:root {
  --popper-theme-background-color: var(--contrast-color);
  --popper-theme-background-color-hover: var(--contrast-color);
  --popper-theme-text-color: var(--text-color);
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 16px;
  --popper-theme-padding: #{$spacing-base * 6};
  --popper-theme-box-shadow: var(--shadow-8);
}

.dense {
  .popper {
    --popper-theme-border-radius: 4px;
    --popper-theme-padding: #{$spacing-base * 3} #{$spacing-base * 2};
  }
}

.transparent {
  .popper {
    --popper-theme-background-color: transparent;
    --popper-theme-background-color-hover: transparent;
    --popper-theme-padding: 0;
    --popper-theme-box-shadow: transparent;
  }
}
</style>
