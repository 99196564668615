import { TroProjectResult, TroResultPrograms } from '@/api/openapi';
import { roundNumber } from '@/common/formatter';

type ValueType = string | number | Array<string | number>;
type HeaderValueType = Map<string, ValueType>;

function parseValue(value: ValueType, sep) {
  if (Array.isArray(value)) {
    return value.join(sep);
  } else {
    return `${value}`;
  }
}

function parseHeader(header: HeaderValueType, sep: string): string[] {
  const values = new Array<string>();
  header.forEach((v, k) => {
    const value = parseValue(v, sep);
    values.push(`${k}:,${value}`);
  });
  return values;
}

type CommonHeaderArgs = {
  isRegular: boolean;
  isBudgetPlanning: boolean;
  combination: string;
  area: string;
  duration: string;
  limit?: number;
  candidateNum?: number;
  confirmedCandidates: Array<string> | string;
  programNum?: number;
  stationSetting?: string;
  targetSampleNum: string;
  numOfEffectiveContacts: number;
  isCmCombination: boolean;
  cmDuration?: string;
  cmType?: string;
  cmCompanyName?: string;
  cmProductNames?: string;
  cmTargets?: string;
  created: string;
};

type RegularHeaderArgs = {
  isRegular: true;
  providingNum?: number;
  confirmedProviding: Array<string> | string;
  excludeProviding: Array<string> | string;
  excludeCandidates: Array<string> | string;
  fixed?: string;
} & CommonHeaderArgs;

type OneShotHeaderArgs = {
  isRegular: false;
  refDuration?: string;
} & CommonHeaderArgs;

type HeaderArgs = RegularHeaderArgs | OneShotHeaderArgs;

const HEADER_TITLE = 'TVAL - TRO';

function constructTroHeader(args: HeaderArgs) {
  const value: HeaderValueType = new Map();
  if (args.isRegular) {
    value.set('番組タイプ', 'レギュラー番組');
  } else {
    value.set('番組タイプ', '単発');
  }
  value.set('上限指定', args.combination);
  value.set('エリア', args.area);
  if (args.isRegular) {
    value.set('集計期間', args.duration);
  } else {
    if (args.refDuration) {
      value.set('プランニング期間', args.refDuration);
    }
    value.set('実績参照期間', args.duration);
  }
  if (args.isBudgetPlanning) {
    value.set('予算', `"${args.limit?.toLocaleString()}千円"`);
  } else {
    value.set('番組数', `${args.programNum}`);
  }
  if (args.isRegular) {
    if (args.providingNum) {
      value.set('提供中番組数', args.providingNum);
    }
    value.set('提供中採用番組', args.confirmedProviding);
    value.set('提供中除外番組', args.excludeProviding);
  }
  if (args.candidateNum) value.set('候補番組数', args.candidateNum);
  value.set('候補採用番組数', args.confirmedCandidates);
  if (args.isRegular) {
    value.set('候補除外番組', args.excludeCandidates);
  }
  if (args.stationSetting) {
    value.set('番組数を指定', args.stationSetting);
  }
  if (args.isRegular && args.fixed) {
    value.set('放送時間を固定', args.fixed);
  }
  value.set('ターゲット（期間内有効サンプル数）', `"${args.targetSampleNum}"`);
  value.set('有効接触回数', args.numOfEffectiveContacts);
  value.set(
    '既存CMとの組み合わせ分析',
    args.isCmCombination ? '既存CMを組み合わせる' : '指定なし'
  );
  if (args.isCmCombination) {
    if (args.cmDuration) value.set('既存CM（参照期間）', args.cmDuration);
    if (args.cmType) value.set('既存CM（CM種別）', args.cmType);
    if (args.cmCompanyName)
      value.set('既存CM（カンパニー名）', args.cmCompanyName);
    if (args.cmProductNames)
      value.set('既存CM（商品／ブランド名）', args.cmProductNames);
    if (args.cmTargets) value.set('既存CM（対象CM素材）', args.cmTargets);
  }
  value.set('視聴者定義', '番組放送時間の1/3以上視聴者');
  value.set('レポート作成日時', args.created);
  value.set('データ提供元', '"Switch Media, Inc."');

  return value;
}

function constructTroBody(
  result: TroProjectResult,
  reach: number,
  isRegular: boolean,
  isBudgetPlanning: boolean
) {
  const rightTable = new Array<Array<string | number>>();
  // 1行目
  const ranks = result.summary.totalCosts.map((_, idx) => {
    if (idx === 0 && isRegular) {
      return '入替前';
    } else if ((idx === 1 && isRegular) || (idx === 0 && !isRegular)) {
      return '最適';
    } else if (isRegular) {
      return `候補${idx - 1}`;
    } else {
      return `候補${idx}`;
    }
  });
  rightTable.push(['', ...ranks]);

  const empties = new Array<string>(ranks.length).fill('-');
  if (isBudgetPlanning) {
    rightTable.push([
      '出稿金額（千円）',
      ...result.summary.totalCosts.map(v => {
        if (isBudgetPlanning) {
          return v;
        } else {
          return '-';
        }
      })
    ]);
  }
  const totalReachNumbers = result.summary.totalReachNumbers?.map(v =>
    v.toFixed(6)
  );
  rightTable.push(['ターゲットリーチ人数', ...totalReachNumbers]);
  if (isRegular) {
    const delta =
      result.summary.totalReachNumberDeltas?.map(v => v.toFixed(6)) ?? empties;
    rightTable.push([
      '増加分',
      ...delta.map((v, idx) => {
        if (idx === 0) {
          return '-';
        } else {
          return v;
        }
      })
    ]);
  }
  rightTable.push([
    'ターゲットリーチ率',
    ...result.summary.totalReachRates.map(v => roundNumber(v, 7))
  ]);
  if (isRegular) {
    const delta =
      result.summary.totalReachRateDeltas?.map(v => roundNumber(v, 7)) ??
      empties;
    rightTable.push([
      '増加分',
      ...delta.map((v, idx) => {
        if (idx === 0) {
          return '-';
        } else {
          return v;
        }
      })
    ]);
  }
  const costPerReach = result.summary.costsPerReach.map(v => v.toFixed(6));
  if (isBudgetPlanning) {
    rightTable.push([
      'ターゲットリーチ人数単価',
      ...costPerReach.map(v => {
        if (isBudgetPlanning) {
          return v;
        } else {
          return '-';
        }
      })
    ]);
  }
  const rightHeaderSize = rightTable.length;
  const improves =
    result.summary.improvementAmounts?.map(v => v.toFixed(6)) ?? empties;
  rightTable.push([
    'ターゲットリーチ改善金額(千円)',
    ...improves.map((v, idx) => {
      if (idx === 0 || !isBudgetPlanning || !isRegular) {
        return '-';
      } else {
        return v.replace(/,/g, '');
      }
    })
  ]);

  result.sponsoredProgram?.forEach((v, idx) => {
    const reachImpact = v.reachImpact.map(
      value =>
        `${value === 0 ? '0.000000' : value ? roundNumber(value, 7) : '-'}`
    );
    if (idx === 0) {
      rightTable.push(['リーチ貢献度', ...reachImpact]);
    } else {
      rightTable.push(['', ...reachImpact]);
    }
  });

  result.candidateProgram.forEach((v, idx) => {
    const reachImpact = v.reachImpact.map(
      value =>
        `${value === 0 ? '0.000000' : value ? roundNumber(value, 7) : '-'}`
    );
    if (
      (result.sponsoredProgram === undefined ||
        result.sponsoredProgram.length === 0) &&
      idx === 0
    ) {
      rightTable.push(['リーチ貢献度', ...reachImpact]);
    } else {
      rightTable.push(['', ...reachImpact]);
    }
  });

  const leftTable = new Array<Array<string | number>>();
  const leftHeader = [
    '分類',
    '番組名',
    '番組毎リーチ（R1+）',
    '放送局',
    isRegular ? '提供曜日' : '放送日',
    '開始時刻',
    '終了時刻'
  ];

  if (reach > 1) {
    const idx = leftHeader.indexOf('放送局');
    leftHeader.splice(idx, 0, `番組毎リーチ（R${reach}+）`);
  }
  // 予算: 提供金額（千円） 放送局の前
  if (isBudgetPlanning) {
    const idx = leftHeader.indexOf('放送局');
    leftHeader.splice(idx, 0, '提供金額（千円）');
  }

  // ヘッダーの空白部分の追加
  const leftEmptyRow = new Array<string>(leftHeader.length).fill('');
  const leftEmptyRows = new Array(rightHeaderSize).fill(leftEmptyRow);
  leftTable.push(...leftEmptyRows);
  leftTable.push(leftHeader);

  const addRows = (
    v: TroResultPrograms,
    programType: string
  ): Array<string> => {
    const row = new Array<string>();
    row.push(programType);
    // 番組名
    row.push(v.programName);
    // 番組毎リーチ（R1+）
    row.push(
      `${
        v.fixedProgramReaches !== undefined
          ? roundNumber(Number(v.fixedProgramReaches), 7)
          : '-'
      }`
    );
    // 番組毎リーチ（R${reach}+）
    if (reach > 1) {
      row.push(
        `${
          v.variableProgramReaches !== undefined
            ? roundNumber(Number(v.variableProgramReaches), 7)
            : '-'
        }`
      );
    }
    // 提供月額（千円）
    if (isBudgetPlanning) {
      row.push(v.monthlyCost ?? '-');
    }
    // 放送局
    row.push(v.stationCode ?? '-');
    // 提供曜日
    // 提供日
    if (isRegular) {
      row.push(v.week ?? '-');
    } else {
      row.push(v.broadcastDate ?? '-');
    }
    // 開始時刻
    row.push(v.startTime ? v.startTime.slice(0, 5) : '-');
    // 終了時刻
    row.push(v.endTime ? v.endTime.slice(0, 5) : '-');
    return row;
  };

  if (result.sponsoredProgram) {
    const rows = result.sponsoredProgram.map(v => addRows(v, '提供中'));
    leftTable.push(...rows);
  }
  if (result.candidateProgram) {
    const rows = result.candidateProgram.map(v => addRows(v, '候補'));
    leftTable.push(...rows);
  }
  // 確認用
  const empty = new Array<Array<string>>(leftTable.length).fill(['']);
  // 行のサイズチェック
  const tableRowNum = leftTable.length;
  const checkRowSize =
    tableRowNum === empty.length && tableRowNum === rightTable.length;
  if (!checkRowSize) {
    throw Error('');
  }
  const table = new Array<Array<string | number>>();
  for (let i = 0; i < tableRowNum; i++) {
    const val = [...leftTable[i], ...empty[i], ...rightTable[i]];
    table.push(val);
  }
  return table;
}

function parseTable(
  args: TroProjectResult,
  sep = ',',
  lineFeed = '\r\n'
): string {
  const numOfEffectiveContacts = args.conditions.numOfEffectiveContacts.replace(
    /[^0-9]/g,
    ''
  );
  const isRegular = args.conditions.programType !== '単発番組';
  const common: CommonHeaderArgs = {
    isRegular,
    area: args.conditions.area,
    numOfEffectiveContacts: Number(numOfEffectiveContacts),
    isBudgetPlanning: args.conditions.troCombination === '予算',
    candidateNum: args.conditions.numOfCandidatePrograms,
    cmCompanyName: args.conditions.cmCompanyName,
    cmDuration: args.conditions.cmReferablePeriod,
    cmProductNames: args.conditions.cmProductName,
    cmTargets: args.conditions.cmTargetCmCreative,
    cmType: args.conditions.cmType,
    combination: args.conditions.troCombination,
    duration: args.conditions.searchPeriod,
    created: args.conditions.reportCreatedDate,
    programNum: args.conditions.numOfPrograms,
    stationSetting: args.conditions.stationSetting,
    isCmCombination: args.conditions.cmType !== null,
    limit:
      args.conditions.troCombination === '予算'
        ? args.conditions.budget
        : args.conditions.numOfPrograms,
    targetSampleNum: args.conditions.target,
    confirmedCandidates: args.conditions.sponsoredIncludePrograms ?? '指定なし'
  };
  let headerArgs: HeaderArgs;
  if (isRegular) {
    headerArgs = {
      ...common,
      isRegular: true,
      providingNum: args.conditions.numOfSponsoredPrograms,
      confirmedCandidates:
        args.conditions.candidateIncludePrograms ?? '指定なし',
      excludeCandidates: args.conditions.candidateExcludePrograms ?? '指定なし',
      confirmedProviding:
        args.conditions.sponsoredIncludePrograms ?? '指定なし',
      excludeProviding: args.conditions.sponsoredExcludePrograms ?? '指定なし',
      fixed: args.conditions.broadcastTimeType
    };
  } else {
    headerArgs = {
      ...common,
      isRegular: false,
      refDuration: args.conditions.planningPeriod
    };
  }
  const title = HEADER_TITLE;
  const header = constructTroHeader(headerArgs);
  const body = constructTroBody(
    args,
    headerArgs.numOfEffectiveContacts,
    headerArgs.isRegular,
    common.isBudgetPlanning
  );
  const headers = parseHeader(header, sep);
  const bodies = body.map(v => parseValue(v, sep));
  return (
    title + lineFeed + headers.join(lineFeed) + lineFeed + bodies.join(lineFeed)
  );
}

function downloadCsv(
  projectId: string,
  args: TroProjectResult,
  sep = ',',
  lineFeed = '\r\n'
): void {
  const content = parseTable(args, sep, lineFeed);
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, content], { type: 'text/css' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  const re = new RegExp(
    '(\\d{4})年(\\d{2})月(\\d{2})日.*(\\d{4})年(\\d{2})月(\\d{2})日'
  );
  const match = args.conditions.searchPeriod.match(re);
  const dt = match
    ? `${match[1]}${match[2]}${match[3]}-${match[4]}${match[5]}${match[6]}`
    : '';
  a.download = `TVAL_TRORESULT_${projectId}_${dt}.csv`;
  document.body.appendChild(a);
  a.click();
  a?.parentNode?.removeChild(a);
}

export default downloadCsv;
