const UPPER_DIVISION_NUMBER = 100_000_000;
const LOWER_DIVISION_NUMBER = 10_000;

/**
 * 指定された数値を指定された小数の位で四捨五入し、返却する。
 *
 * @param value 対象の数値
 * @param decimalPlace 四捨五入する小数の位
 */
export const round = (value: number, decimalPlace: number): number => {
  if (Number.isInteger(value) || typeof value != 'number') return value;
  const d = 10 ** (decimalPlace - 1);
  // return Math.round(Number((value * d).toFixed(decimalPlace))) / d;
  // NOTE: value * d の計算にjsで誤差が出るため文字列に変換
  // 9999.345 * 100 = 999934.4999999999 --> 期待値は 999934.5
  const _split = value.toString().split('.');
  if (_split[1].length < decimalPlace) return value;
  const p = _split[0].length + (decimalPlace - 1);
  const s =
    _split.join('').slice(0, p) +
    (_split[1] ? '.' + _split.join('').slice(p) : '');
  return Math.round(Number(s)) / d;
};

/**
 * 指定された数値を指定された小数の位で四捨五入し、桁区切りした文字列を返却する。
 *
 * @param value 対象の数値
 * @param decimalPlace 四捨五入する小数の位
 * @param useGrouping 桁区切りの有無
 */
export const roundNumber = (
  value: number,
  decimalPlace = 1,
  useGrouping = true
): string => {
  if (typeof value != 'number') return value;
  return round(value, decimalPlace).toLocaleString(undefined, {
    minimumFractionDigits: decimalPlace - 1,
    maximumFractionDigits: decimalPlace - 1,
    useGrouping: useGrouping
  });
};
/**
 * 指定された数値に対して、以下の処理を行った結果の文字列を返却する。
 * 要約: 未満は小数点あり、以上は整数丸め
 * - LOWER_DIVISION_NUMBER未満はroundNumber + unit 。
 * - LOWER_DIVISION_NUMBER以上はresolveNumberWithUnitを戻す
 *
 * @param value
 * @param decimalPlace
 * @param unit
 * @returns
 */
export const roundNumberWithUnit = (
  value: number,
  decimalPlace = 3,
  unit = '回'
): string => {
  if (value < LOWER_DIVISION_NUMBER) {
    return roundNumber(value, decimalPlace) + ' ' + unit;
  } else {
    return resolveNumberWithUnit(value, decimalPlace, unit);
  }
};

/**
 * 指定された数値に対して、以下の処理を行った結果の文字列を返却する。
 * - 一定の数値で除算することによる単位変換
 * - 指定された小数の位での四捨五入
 * - 桁区切り
 * - 指定された単位文字列の付与
 *
 * @param value 対象の数値
 * @param decimalPlace 四捨五入する小数の位
 * @param unit 付与する単位文字列
 */
export const resolveNumberWithUnit = (
  value: number,
  decimalPlace = 3,
  unit = '回',
  isThousand = false
): string =>
  resolveNumber(value, decimalPlace, isThousand) +
  ' ' +
  resolveUnit(value, unit, isThousand);

/**
 * 指定された数値に対して、以下の処理を行った結果の文字列を返却する。
 * - 一定の数値で除算することによる単位変換
 * - 指定された小数の位での四捨五入
 * - 桁区切り
 *
 * 注意) 汎用的な関数のように思えるが、以下の仕様が混じっているため、使い方には注意が必要。
 *  https://www.notion.so/switchm/eec790b971304533ad1d72eaa9d3a826#b9da80fb01aa4a278356fcc1064468fb
 *
 * @param value 対象の数値
 * @param decimalPlace 四捨五入する小数の位
 */
export const resolveNumber = (
  value: number,
  decimalPlace = 3,
  isThousand = false
): string => {
  if (typeof value != 'number') return value;
  if (!isThousand && Math.abs(value) >= UPPER_DIVISION_NUMBER) {
    return round(value / UPPER_DIVISION_NUMBER, decimalPlace).toLocaleString(
      undefined,
      {
        minimumFractionDigits: decimalPlace - 1,
        maximumFractionDigits: decimalPlace - 1
      }
    );
  }
  if (Math.abs(value) >= LOWER_DIVISION_NUMBER) {
    return round(value / LOWER_DIVISION_NUMBER, 1).toLocaleString();
  }
  return round(value, 1).toLocaleString();
};

/**
 * 指定された数値に対応する、単位を返却する。
 *
 * @param value 対象の数値
 * @param unit 付与する単位文字列
 */
export const resolveUnit = (
  value: number,
  unit = '回',
  isThousand = false
): string => {
  if (typeof value != 'number') return value;
  if (!isThousand && Math.abs(value) >= UPPER_DIVISION_NUMBER)
    return `億${unit}`;
  if (Math.abs(value) >= LOWER_DIVISION_NUMBER) return `万${unit}`;
  return unit;
};
