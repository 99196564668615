interface UseStaionColorReturnType {
  getStationColorByCode: (code?: string) => string;
  getStationColorByName: (name?: string) => string;
}

export const useStationColor = (): UseStaionColorReturnType => {
  const getStationColorByCode = (code?: string) => {
    switch (code) {
      case 'NTV':
      case 'YTV':
      case 'CTV':
        return 'var(--ntv-color)';
      case 'EX':
      case 'ABC':
      case 'NBN':
        return 'var(--ex-color)';
      case 'TBS':
      case 'MBS':
      case 'CBC':
        return 'var(--tbs-color)';
      case 'TX':
      case 'TVO':
      case 'TVA':
        return 'var(--tx-color)';
      case 'CX':
      case 'KTV':
      case 'THK':
        return 'var(--cx-color)';
      case 'NHK':
        return 'var(--nhk-color)';
      default:
        return 'var(--contrast-color)';
    }
  };
  const getStationColorByName = (name?: string) => {
    switch (name) {
      case '日本テレビ':
      case '読売テレビ':
      case '中京テレビ':
      case '日本テレビ系列':
        return 'var(--ntv-color)';
      case 'テレビ朝日':
      case 'ABCテレビ':
      case 'メ～テレ':
      case 'テレビ朝日系列':
        return 'var(--ex-color)';
      case 'TBS':
      case 'MBS毎日放送':
      case 'CBCテレビ':
      case 'TBS系列':
        return 'var(--tbs-color)';
      case 'テレビ東京':
      case 'テレビ大阪':
      case 'テレビ愛知':
      case 'テレビ東京系列':
        return 'var(--tx-color)';
      case 'フジテレビ':
      case '関西テレビ':
      case '東海テレビ':
      case 'フジテレビ系列':
        return 'var(--cx-color)';
      default:
        return 'var(--contrast-color)';
    }
  };
  return {
    getStationColorByCode,
    getStationColorByName
  };
};

interface UseCompareColorsReturnType {
  getCompareColorsByType: (id?: string) => string[];
}

export const useCompareColors = (): UseCompareColorsReturnType => {
  const getCompareColorsByType = (id?: string) => {
    switch (id) {
      case 'past':
        return [
          'var(--chart-color-1)',
          'var(--chart-color-2)',
          'var(--chart-color-3)',
          'var(--chart-color-4)'
        ];
      case 'competitive':
        return [
          'var(--chart-color-1)',
          'var(--chart-color-2)',
          'var(--chart-color-3)',
          'var(--chart-color-4)'
        ];
      case 'own_company':
        return [
          'var(--chart-color-1)',
          'var(--chart-color-2)',
          'var(--chart-color-3)',
          'var(--chart-color-4)'
        ];
      default:
        // 'past'と同じ
        return [
          'var(--chart-color-1)',
          'var(--chart-color-2)',
          'var(--chart-color-3)',
          'var(--chart-color-4)'
        ];
    }
  };

  return {
    getCompareColorsByType
  };
};
