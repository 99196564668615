<template>
  <Flex align-center>
    <Label
      v-if="label"
      :bold="boldInActive ? on : undefined"
      class="mr-1"
      :size="size"
    >
      {{ label }}
    </Label>
    <button
      v-ripple
      type="button"
      class="ui-switch"
      :class="{ on, disabled }"
      :disabled="disabled"
      @click="onClickSwitch"
    >
      <div class="handle"></div>
    </button>
  </Flex>
</template>
<script>
import { defineComponent, computed } from 'vue';
import Flex from '@/components/layout/Flex.vue';
import Label from '@/components/ui/Label.vue';

export default defineComponent({
  components: { Flex, Label },
  props: {
    modelValue: Boolean,
    disabled: Boolean,
    /**
     * `<Label />` コンポーネント
     */
    label: String,
    size: {
      type: String,
      default: 's'
    },
    boldInActive: Boolean,
    eventSkip: Boolean
  },
  setup(props, { emit }) {
    const on = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value)
    });
    return {
      on,
      onClickSwitch: () => {
        if (!props.eventSkip) on.value = !on.value;
      }
    };
  }
});
</script>
<style lang="scss" scoped>
.ui-switch {
  position: relative;
  border: none;
  height: 16px;
  width: 36px;
  border-radius: 16px;
  border: 1px solid var(--border-color);

  &.on {
    border-color: var(--border-focus-color);
    background-color: var(--background-focus-color);
  }

  > .handle {
    position: absolute;
    top: 1px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--dark-60-color);
    transition: left 0.25s;
  }
  &.on {
    transition: background-color 0.25s;
    > .handle {
      background-color: var(--contrast-color);
      left: 20px;
      transition: left 0.25s;
    }
  }

  &.disabled {
    border-color: var(--border-disabled-color);
    background-color: var(--background-disabled-color);
    cursor: inherit;
    > .handle {
      background-color: var(--background-disabled-color);
    }
  }
}
</style>
